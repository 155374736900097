import Coverdash from '../../features/CoverDash/types/Coverdash';
import { borrowerPortalApi } from './borrowerPortalBase';

export const coverdashApi = borrowerPortalApi.injectEndpoints({
  endpoints: (builder) => ({
    getCoverdashApplicationId: builder.query<Coverdash, string>({
      query: (qFormUid) => `api/Coverdash/getApplication?formId=${qFormUid}`,
    }),
  }),
  overrideExisting: false,
});

export const { useGetCoverdashApplicationIdQuery } = coverdashApi;
