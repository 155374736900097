import CoverdashQuotes from '@coverdash/quotes';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { useGetCoverdashApplicationIdQuery } from '../../app/services/coverdash';
import './CoverdashLanding.scss';

const CoverdashLanding = (): JSX.Element => {
  const coverdashLicence = process.env.COVERDASH_LICENCE;
  const coverdashEnv = process.env.COVERDASH_ENV;
  const [searchParams] = useSearchParams();
  const qFormGuid = searchParams.get('formId') || '';
  const { data, isError } = useGetCoverdashApplicationIdQuery(qFormGuid);
  useEffect(() => {
    if (isError) {
      window.location.href = `${process.env.COVERDASH_URL}`;
    }
  }, [isError]);
  return (
    <>
      <Helmet>
        <title>Coverdash - Business.LendingTree.com</title>
      </Helmet>
      <div className="coverdash">
        <CoverdashQuotes
          applicationSubmissionId={data?.applicationId}
          autoResize={false}
          env={coverdashEnv}
          hideScrollbar={true}
          hybrid={true}
          license={coverdashLicence}
          styles={{ minHeight: '100vh' }}
        />
      </div>
    </>
  );
};

export default CoverdashLanding;
